<template>
  <div>
    <strong>{{config.header}}</strong>
    <div :id="id" :style="`width: ${config.width}; height: ${config.height};`"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { v4 as uuid } from 'uuid'
import theme from '@/libs/colorTheme'
echarts.registerTheme('kpmg', theme)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  props: ['config'],
  computed: {
  },
  created: async function () {
    await delay(10)
    this.load()
  },
  data () {
    return {
      id: uuid()
    }
  },
  methods: {
    load: async function () {
      const options = {
        series: [
          {
            name: this.config.name,
            type: 'gauge',
            progress: {
              show: true,
              width: 18
            },
            axisLine: {
              lineStyle: {
                width: 18
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 15,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            axisLabel: {
              distance: 25,
              color: '#999',
              fontSize: 10
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 25,
              itemStyle: {
                borderWidth: 10
              }
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              formatter: '{value} %',
              fontSize: 40,
              offsetCenter: [0, '70%']
            },
            data: this.config.data
          }
        ]
      }
      await delay(10)
      echarts.init(document.getElementById(this.id), 'kpmg').setOption(options)
    },
    reload: function () {
      console.log('triggered reload')
      echarts.dispose(document.getElementById(this.id))
      this.load()
    }
  },
  watch: {
    'config': 'reload'
  }
}
</script>

<style>
</style>
