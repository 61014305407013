<template>
<div>
  <b-container fluid class="mb-5">
    <b-row class="py-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Interoperability assessment</h1>
        <p>Review and analyse common metrics and data elements across frameworks..</p>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="3" class="shadow bg-white py-4">
        <h5>Select frameworks</h5>
        <p>Select frameworks for comparison. Minimum two required.</p>
        <div>
          <b-form-checkbox-group stacked v-model="frameworksSelected" :options="frameworksOptions" name="frameworks"></b-form-checkbox-group>
        </div>
      </b-col>
      <b-col>
        <b-container fluid>
          <b-row class="mb-5 shadow bg-white py-4" v-if="frameworksSelected.length > 1">
            <b-col>
              <h5>Common metrics and data elements</h5>
              <p>Analysis of common metrics and data frameworks for selected frameworks.</p>
              <b-container>
                <b-row class="mb-1">
                  <b-col cols="2">
                    &nbsp;
                  </b-col>
                  <b-col class="text-center">
                    <div>
                      <gauge :config="{
                        header: 'Common metrics',
                        width: '100%',
                        height: '400px',
                        data: [
                          { value: Math.round((metricsCommon.length / metricsUniqe.length)*100) },
                        ]
                      }"></gauge>
                    </div>
                  </b-col>
                  <b-col class="text-center">
                    <gauge :config="{
                        header: 'Common data elements',
                        width: '100%',
                        height: '400px',
                        data: [
                          { value: Math.round((dataelementsCommon.length / dataelementsUnique.length)*100) },
                        ]
                      }"></gauge>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="2">
                    All
                  </b-col>
                  <b-col class="text-center">
                    <div>
                      {{  metricsUniqe.length }}
                    </div>
                  </b-col>
                  <b-col class="text-center">
                    <div>
                      {{  dataelementsUnique.length }}
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col cols="2">
                    Common
                  </b-col>
                  <b-col class="text-center">
                    <div>
                      {{ metricsCommon.length }}
                    </div>
                  </b-col>
                  <b-col class="text-center">
                    <div>
                      {{  dataelementsCommon.length }}
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row class="mb-5 shadow bg-white py-4" v-if="frameworksSelected.length > 1">
            <b-col>
              <h5>Composition of selected frameworks</h5>
              <p>Metrics and data elements for each of the selected frameworks.</p>
              <b-container>
                <b-row>
                  <b-col>
                    <strong>Framework</strong>
                  </b-col>
                  <b-col class="text-right">
                    <strong>Metrics</strong>
                  </b-col>
                  <b-col class="text-right">
                    <strong>Data elements</strong>
                  </b-col>
                </b-row>
                <b-row v-for="framework in frameworks" :key="'framework' + framework.id">
                  <b-col>
                    {{ framework.name }}
                  </b-col>
                  <b-col class="text-right">
                    {{ framework.metrics.length }}
                  </b-col>
                  <b-col class="text-right">
                    {{ framework.dataelements.length }}
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <b-row class="mb-5 shadow bg-white py-4" v-if="matrixDENumbers.length > 1 && frameworksSelected.length > 1">
            <b-col>
              <h5>Data elements</h5>
              <p>Most common data elements</p>
              <b-container fluid v-if="!calculatingDataelements">
                <b-row class="mb-2">
                  <b-col>
                    <strong>Data element</strong>
                  </b-col>
                  <b-col cols="2" class="text-right">
                    <strong>Supported metrics</strong>
                  </b-col>
                </b-row>
              </b-container>
              <b-container fluid v-if="!calculatingDataelements" style="height: 40vh; overflow-y: auto;  overflow-x: auto;">
                <b-row class="mb-0" v-for="item in dataelementsX" :key="'dataelement-x-'+ item.id">
                  <b-col>
                      <router-link :to="{ name: 'DataElement', params: { id: item.id }}">{{  item.name }}</router-link>
                  </b-col>
                  <b-col cols="2" class="text-right">
                      <router-link :to="{ name: 'DataElement', params: { id: item.id }}">{{ item.metricsCount }}</router-link>
                  </b-col>
                </b-row>
              </b-container>
              <div v-if="calculatingDataelements"><b-spinner></b-spinner></div>
            </b-col>
          </b-row>
          <b-row class="mb-5 shadow bg-white py-4" v-if="matrixDENumbers.length > 1 && frameworksSelected.length > 1">
            <b-col>
              <h5>Interoperability matrices</h5>
              <p>Analysis of common data elements for each pair of frameworks.</p>
              <b-container fluid>
                <b-row class="mb-5">
                  <b-col>
                    <strong>Number of common data elements</strong>
                    <table>
                      <tr v-for="(row, rowIndex) in matrixDENumbers" :key="'mden-row-' + rowIndex">
                        <td style="padding: 5px; border-style: solid; border-color: #00348d; border-width: 2px;" v-for="(cell, cellIndex) in row" :key="'mden-cell-' + rowIndex + cellIndex">
                          <div v-if="rowIndex === 0 && cellIndex != 0" style="writing-mode: vertical-rl; text-orientation: mixed; margin-top: 10px; margin-bottom: 10px;">{{ cell }}</div>
                          <div v-else>{{ cell }}</div>
                        </td>
                      </tr>
                    </table>
                  </b-col>
                  <b-col>
                    <strong>% of common data elements</strong>
                    <table>
                      <tr v-for="(row, rowIndex) in matrixDEPercentages" :key="'mden-row-' + rowIndex">
                        <td style="border-style: solid; border-color: #00348d; border-width: 2px;" v-for="(cell, cellIndex) in row" :key="'mden-cell-' + rowIndex + cellIndex">
                          <div v-if="rowIndex === 0 && cellIndex != 0" style="writing-mode: vertical-lr; text-orientation: mixed; padding: 5px;">{{ cell }}</div>
                          <div v-if="rowIndex === 0 && cellIndex === 0" style="padding: 5px;">{{ cell }}</div>
                          <div v-if="rowIndex != 0 && cellIndex === 0" style="padding: 5px;">{{ cell }}</div>
                          <div v-if="rowIndex !=0 && cellIndex !=0" :style="'padding: 5px; background-color: rgb(' + Math.round(255 - (100 * cell / 100)) + ', ' + Math.round(255 - (100 * cell/100)) + ', 255)'">{{ cell }}</div>
                        </td>
                      </tr>
                    </table>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import gauge from '@/components/charts/v1/gaugeSimple.vue'
import delay from '@/libs/delay'

export default {
  name: 'Interoperability',
  components: {
    gauge
  },
  computed: {
    dataelements: function () {
      return this.$store.state.dataelements
    },
    dataelementsUnique: function () {
      // using lodash get an array of all unique dataelements by id from all this.frameworks
      const dataelements = this.frameworks.map(framework => framework.dataelements)
      const dataelementsUnique = _.uniqBy(_.flatten(dataelements), 'id')
      return dataelementsUnique
    },
    dataelementsCommon: function () {
      // using lodash get all dataelements that are common across all frameworks within this.frameworks
      const dataelements = this.frameworks.map(framework => framework.dataelements)
      const dataelementsCommon = _.intersectionBy(...dataelements, 'id')
      return dataelementsCommon
    },
    frameworks: function () {
      const frameworksAll = this.$store.state.reportingframeworks
      const frameworks = frameworksAll.filter(framework => this.frameworksSelected.includes(framework.id))
      frameworks.forEach(framework => {
        const metrics = this.metrics.filter(metric => metric.reportingframeworks.find(f => f.id === framework.id))
        framework.metrics = metrics
      })
      // go through frameworks and for each framework create a property dataelements which contains all unique dataelements from metrics property
      frameworks.forEach(framework => {
        const dataelements = []
        framework.metrics.forEach(metric => {
          metric.dataelements.forEach(dataelement => {
            dataelements.push(dataelement)
          })
        })
        framework.dataelements = _.uniqBy(dataelements, 'id')
      })
      console.log('frameworks')
      console.log(frameworks)
      return frameworks
    },
    frameworksOptions: function () {
      return this.$store.state.reportingframeworks.map(framework => {
        return {
          value: framework.id,
          text: framework.name
        }
      })
    },
    matrixDENumbers: function () {
      // create a matrix where each row is a framework name and each column is a framework name and each intersection is the number of common dataelements
      const matrix = []
      const firstRow = ['interoperability']
      this.frameworks.forEach(framework => {
        firstRow.push(framework.name)
      })
      matrix.push(firstRow)
      this.frameworks.forEach(framework => {
        const row = [framework.name]
        this.frameworks.forEach(framework2 => {
          const common = _.intersectionBy(framework.dataelements, framework2.dataelements, 'id')
          row.push(common.length)
        })
        matrix.push(row)
      })
      return matrix
    },
    matrixDEPercentages: function () {
      // create a matrix where each row is a framework name and each column is a framework name and each intersection is the percentage of common dataelements to all unique data elements for these two frameworks
      const matrix = []
      const firstRow = ['interoperability']
      this.frameworks.forEach(framework => {
        firstRow.push(framework.name)
      })
      matrix.push(firstRow)
      this.frameworks.forEach(framework => {
        const row = [framework.name]
        this.frameworks.forEach(framework2 => {
          const common = _.intersectionBy(framework.dataelements, framework2.dataelements, 'id')
          const unique = _.unionBy(framework.dataelements, framework2.dataelements, 'id')
          const percentage = Math.round((common.length / unique.length) * 100)
          row.push(percentage)
        })
        matrix.push(row)
      })
      return matrix
    },
    metrics: function () {
      return this.$store.state.metrics
    },
    metricsUniqe: function () {
      // using lodash get an array of all unique metrics by id from all this.frameworks
      const metrics = this.frameworks.map(framework => framework.metrics)
      const metricsUniqe = _.uniqBy(_.flatten(metrics), 'id')
      return metricsUniqe
    },
    metricsCommon: function () {
      // using lodash get all metrics that are common across all frameworks within this.frameworks
      const metrics = this.frameworks.map(framework => framework.metrics)
      const metricsCommon = _.intersectionBy(...metrics, 'id')
      return metricsCommon
    }
  },
  created: function () {
  },
  data () {
    const data = {
      calculatingDataelements: false,
      dataelementsX: [],
      frameworksSelected: []
    }
    return data
  },
  methods: {
    getDataelements: async function () {
      if (this.frameworksSelected.length < 2 || this.dataelementsUnique.length === 0) {
        return
      }
      this.calculatingDataelements = true
      await delay(1000)
      const elementsAll = this.dataelements
      console.log('starting point')
      console.log(elementsAll)
      // using lodash find all data elements from elementsAll that are in dataelementsUnique
      let elements = _.filter(elementsAll, element => _.find(this.dataelementsUnique, { id: element.id }))
      console.log('common data elements')
      console.log(elements)
      // go through elements and for each metric in metrics go through reportingframeworks and for each reportingframework check if it is in frameworksSelected. If it is not, remove it from reportingframeworks in metrics in elements
      elements.forEach(element => {
        element.metrics.forEach(metric => {
          metric.reportingframeworksSelected = metric.reportingframeworks.filter(f => this.frameworksSelected.includes(f.id))
        })
      })
      console.log('all data elements with metrics cleaned')
      console.log(elements)
      // from elements.metrics remove metrics which have no reportingframeworks
      elements.forEach(element => {
        element.metricsSelected = element.metrics.filter(metric => metric.reportingframeworksSelected.length > 0)
      })
      // gtom elements remove elements which have no metrics
      elements = elements.filter(element => element.metricsSelected.length > 0)
      console.log('elements after removing zero metrics')
      console.log(elements)
      // go through elements and create a new property metricsCount which counts the number of metrics in metrics property
      elements.forEach(element => {
        element.metricsCount = element.metricsSelected.length
      })
      // order elements by metricsCount
      elements.sort((a, b) => b.metricsCount - a.metricsCount)
      console.log('all data elements')
      console.log(elements)
      this.dataelementsX = elements
      this.calculatingDataelements = false
    }
  },
  watch: {
    frameworksSelected: 'getDataelements'
  }
}
</script>
